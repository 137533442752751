<template>
  <b-card
    class="inventoryorderdetail-edit-wrapper"
  >
    <!-- form -->
    <b-form id="inventoryorderdetailForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="盘点编号Id"
            label-for="inventory_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="inventory_id"
              size="sm"
              v-model="inventoryorderdetail.inventory_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="是否要参与盘点"
            label-for="is_inventory"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="is_inventory"
              size="sm"
              v-model="inventoryorderdetail.is_inventory"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="库存ID"
            label-for="stock_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="stock_id"
              size="sm"
              v-model="inventoryorderdetail.stock_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品ID"
            label-for="product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_id"
              size="sm"
              v-model="inventoryorderdetail.product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品编码(69码)"
            label-for="code"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="code"
              size="sm"
              v-model="inventoryorderdetail.code"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="名称"
            label-for="name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="name"
              size="sm"
              v-model="inventoryorderdetail.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品规格"
            label-for="specification"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="specification"
              size="sm"
              v-model="inventoryorderdetail.specification"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="单箱标准数量"
            label-for="box_quantity"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="box_quantity"
              size="sm"
              v-model="inventoryorderdetail.box_quantity"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="产品批次"
            label-for="batch_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="batch_no"
              size="sm"
              v-model="inventoryorderdetail.batch_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="生产日期"
            label-for="production_date"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="production_date"
              size="sm"
              v-model="inventoryorderdetail.production_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="有效日期"
            label-for="expiry_date"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="expiry_date"
              size="sm"
              v-model="inventoryorderdetail.expiry_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="仓位全称"
            label-for="location_fullname"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="location_fullname"
              size="sm"
              v-model="inventoryorderdetail.location_fullname"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="数量=qty+qty_lock"
            label-for="total_qty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="total_qty"
              size="sm"
              v-model="inventoryorderdetail.total_qty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="库存状态 stock_status"
            label-for="status"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="status"
              size="sm"
              v-model="inventoryorderdetail.status"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="盘点人"
            label-for="operator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="operator"
              size="sm"
              v-model="inventoryorderdetail.operator"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="备注"
            label-for="memo"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="memo"
              size="sm"
              v-model="inventoryorderdetail.memo"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="add_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="add_time"
              size="sm"
              v-model="inventoryorderdetail.add_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加人"
            label-for="creator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="creator"
              size="sm"
              v-model="inventoryorderdetail.creator"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改时间"
            label-for="modify_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="modify_time"
              size="sm"
              v-model="inventoryorderdetail.modify_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改人"
            label-for="updator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="updator"
              size="sm"
              v-model="inventoryorderdetail.updator"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import inventoryorderdetailStore from './inventoryorderdetailStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      inventoryorderdetail: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('inventoryorderdetail')) store.registerModule('inventoryorderdetail', inventoryorderdetailStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('inventoryorderdetail')) store.unregisterModule('inventoryorderdetail')
    })

    const edit = function() {
      store.dispatch('inventoryorderdetail/edit', {id: this.id}).then(res => {
        this.inventoryorderdetail = res.data.data
      })
    }

    const view = function() {
      store.dispatch('inventoryorderdetail/view', {id: this.id}).then(res => {
        this.inventoryorderdetail = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('inventoryorderdetail/save', this.inventoryorderdetail).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>